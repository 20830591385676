var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home page"},[_c('h2',{staticClass:"accent--text"},[_c('v-icon',{staticClass:"ml-3",attrs:{"color":"primary","large":""}},[_vm._v("mdi-tie")]),_vm._v(" مديرى التطبيق "),_c('back-button')],1),_c('hr',{staticClass:"my-5 header-divider"}),_c('record-form'),_c('filters-box',{on:{"fetch":function($event){return _vm.fetchRecords({ page: 1 })}}}),_c('div',{staticClass:"mb-3 mt-9 text-left"},[_c('v-btn',{attrs:{"large":"","color":"primary","elevation":"0"},on:{"click":function($event){_vm.SET_FORM_RECORD({});
        _vm.SET_EDIT_MODE(false);
        _vm.SHOW_FORM();}}},[_vm._v(" اضافة مدير "),_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")])],1)],1),_c('v-chip',{staticClass:"mb-2",attrs:{"right":"","label":"","color":"secondary"}},[_c('v-icon',[_vm._v(" mdi-account-circle-outline ")]),_vm._v(" عدد المديرين "+_vm._s(_vm.total_admins))],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"id","loading":_vm.table.loading,"headers":_vm.table.headers,"items":_vm.records,"loading-text":"Downloading Data","no-data-text":"No Data","disable-filtering":"","disable-pagination":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.controls",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","icon":"","color":"secondary"},on:{"click":function($event){_vm.SET_FORM_RECORD(item);
          _vm.SET_EDIT_MODE(true);
          _vm.SHOW_FORM();}}},[_c('v-icon',[_vm._v("mdi-circle-edit-outline")])],1)]}},{key:"item.blocked",fn:function(ref){
          var item = ref.item;
return [(item.active == 0)?_c('v-switch',{attrs:{"inset":"","input-value":false},on:{"change":function($event){return _vm.unblock(item)}}}):_c('v-switch',{attrs:{"color":"success","inset":"","input-value":true},on:{"change":function($event){return _vm.block(item)}}})]}}])}),_c('div',{staticClass:"text-center mt-7"},[_c('v-pagination',{staticClass:"my-4",attrs:{"value":+_vm.getFilter.page,"circle":"","total-visible":"7","length":Number(_vm.totalPages)},on:{"input":_vm.changePage}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }