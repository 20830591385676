<template>
  <div class="home page">
    <h2 class="accent--text">
      <v-icon color="primary" class="ml-3" large>mdi-tie</v-icon>
      مديرى التطبيق
      <back-button />
    </h2>
    <hr class="my-5 header-divider" />

    <!-- CREATE / UPDATE FROM -->
    <record-form />

    <!-- UPDATE / NEW RECORD -->
    <filters-box @fetch="fetchRecords({ page: 1 })" />
    <div class="mb-3 mt-9 text-left">
      <v-btn
        large
        color="primary"
        elevation="0"
        @click="
          SET_FORM_RECORD({});
          SET_EDIT_MODE(false);
          SHOW_FORM();
        "
      >
        اضافة مدير
        <v-icon class="mr-2">mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-chip right class="mb-2" label color="secondary">
      <v-icon> mdi-account-circle-outline </v-icon> عدد المديرين
      {{ total_admins }}</v-chip
    >
    <!-- TABLE -->
    <v-data-table
      item-key="id"
      class="elevation-1"
      :loading="table.loading"
      :headers="table.headers"
      :items="records"
      loading-text="Downloading Data"
      no-data-text="No Data"
      disable-filtering
      disable-pagination
      disable-sort
      hide-default-footer
    >
      <!-- CONTROLS -->
      <template v-slot:item.controls="{ item }">
        <v-btn
          small
          icon
          color="secondary"
          class="mx-1"
          @click="
            SET_FORM_RECORD(item);
            SET_EDIT_MODE(true);
            SHOW_FORM();
          "
        >
          <v-icon>mdi-circle-edit-outline</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.blocked="{ item }">
        <v-switch
          v-if="item.active == 0"
          inset
          :input-value="false"
          @change="unblock(item)"
        ></v-switch>
        <v-switch
          v-else="item.active == 1"
          color="success"
          inset
          :input-value="true"
          @change="block(item)"
        ></v-switch>
      </template>
    </v-data-table>

    <!-- PAGINATION -->
    <div class="text-center mt-7">
      <v-pagination
          :value="+getFilter.page"
          circle
          total-visible="7"
          class="my-4"
          :length="Number(totalPages)"
          @input="changePage"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import RecordForm from "./RecordForm";
import { mapState, mapMutations , mapGetters} from "vuex";
import FiltersBox from "./FiltersBox.vue";
export default {
  name: "admins",
  components: { RecordForm, FiltersBox },

  data: function () {
    return {
      // table
      table: {
        headers: [
          {
            value: "name",
            text: "الاسم",
          },
          {
            value: "email",
            text: "الايميل",
          },
          {
            value: "phone",
            text: "الهاتف",
          },
          {
            value: "blocked",
            text: "تفعيل/تعطيل",
          },
          {
            value: "controls",
            text: "تعديل",
          },
        ],
        loading: false,
      },
    };
  },

  computed: {
    ...mapState("admins", [
      "records",
      "page",
      "totalPages",
      "filters",
      "total_admins",
    ]),
    ...mapGetters("admins", ["getFilter"] )
  },
  watch: {
    $route: {
      async handler(route) {
        if (!route.query.page) {
          await this.$router.replace({ query: { ...route.query, page: 1 } });
          return;
        }
        this.SET_FILTERS({ ...this.getFilter,...route.query });
           this.fetchRecords();
       
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapMutations("admins", [
      "SHOW_FORM",
      "SET_EDIT_MODE",
      "SET_FORM_RECORD",

      "SHOW_RECORD_FORM",

      "SET_FILTERS",
    ]),
    
    changePage(val){
      this.$router
        .replace({
          query: { ...this.$route.query, page: val },
        })
        .catch(() => {});
    },
    fetchRecords() {
      this.table.loading = true;
      // // console.log(page);
      this.$store
        .dispatch("admins/fetchRecords")
        .catch((error) => {
          this.$bus.$emit("showSnackbar", {
            text: "Error while getting Data",
            color: "accent",
          });
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    async unblock(selectedRecord) {
      this.table.loading = true;

      await this.$store
        .dispatch("admins/unblock", { record: { ...selectedRecord } })
        .then(() => {
          this.$bus.$emit("showSnackbar", {
            text: `   UNBLOCKED  ${selectedRecord.name}`,
            color: "success",
          });
          this.fetchRecords();
        })
        .catch((error) => {
          this.$bus.$emit("showSnackbar", {
            text: "Error while unblocking Admin  ",
            color: "red darken-3",
          });
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    async block(selectedRecord) {
      this.table.loading = true;

      await this.$store
        .dispatch("admins/block", { record: { ...selectedRecord } })
        .then(() => {
          this.$bus.$emit("showSnackbar", {
            text: ` Blocked   ${selectedRecord.name}`,
            color: "warning",
          });
          this.fetchRecords();
        })
        .catch((error) => {
          this.$bus.$emit("showSnackbar", {
            text: " Error while blocking Admin",
            color: "red darken-3",
          });
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
  },
};
</script>
