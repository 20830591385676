<template>
  <v-dialog
    :value="showForm"
    @input="(v) => v || HIDE_FORM()"
    max-width="600px"
    scrollable
    persistent
  >
    <v-card>
      <!-- TITLE -->
      <v-card-title class="primary">
        <span class="headline">{{
          editMode ? " Edit Admin" : " Add Admin"
        }}</span>
      </v-card-title>

      <!-- INPUTS -->
      <v-card-text class="pt-5 pb-0">
        <v-container>
          <v-progress-linear
            v-if="formLoading"
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
          <div v-else>
            <v-text-field
              dense
              outlined
              label=" Arabic Name"
              v-model="form.name"
              :error-messages="errors.name"
            ></v-text-field>
            <v-text-field
              dense
              outlined
              dir="ltr"
              label="Email"
              v-model="form.email"
              :error-messages="errors.email"
            ></v-text-field>
            <v-text-field
              dense
              outlined
              dir="ltr"
              label="Phone"
              v-model="form.phone"
              :error-messages="errors.phone"
            ></v-text-field>
            <p>* ادخل رقم الهاتف مسبوق بكود الدولة</p>
            <v-text-field
              dense
              outlined
              type="password"
              label="Password"
              v-model="form.password"
              :error-messages="errors.password"
            ></v-text-field>
          </div>
        </v-container>
      </v-card-text>

      <!-- ACTIONS -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="formLoading"
          color="secondary"
          elevation="1"
          dark
          @click="editMode ? update(form) : create(form)"
          >حفظ</v-btn
        >
          <v-btn
          color="secondary"
          text
          elevation="1"
          dark
          @click="HIDE_FORM()"
          >الغاء</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapState, mapMutations , mapActions} from "vuex";

export default {
  name: "record-form",

  data: function () {
    return {
      form: {},
      errors: {},
      formLoading: false,
    };
  },

  computed: {
    ...mapState("admins", {
      editMode: (state) => state.editMode,
      showForm: (state) => state.showForm,
      formRecord: (state) => {
        return {
          id: state.formRecord.id,
          name: state.formRecord.name,
          email: state.formRecord.email,
          phone: state.formRecord.phone,
          password: state.formRecord.password,
        };
      },
    }),
  },

  watch: {
    formRecord(form) {
      this.form = form;
    },
  },

  methods: {
    ...mapMutations("admins", ["HIDE_FORM"]),
    ...mapActions("admins",["fetchRecords"]),

    create(form) {
      this.formLoading = true;

      this.$store
        .dispatch("admins/create", { form })
        .then((response) => {
          this.$bus.$emit("showSnackbar", {
            text: "Admin Added ",
            color: "success",
          });
          this.HIDE_FORM();
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
          for (let key in error.response.data.errors) {
            this.$bus.$emit("showSnackbar", {
              text: error.response.data.errors[key][0],
              color: "error",
            });
          }
        })
        .finally(() => {
          this.formLoading = false;
          this.fetchRecords()
        });
    },

    update(form) {
      this.formLoading = true;
      // console.log({ form });
      this.$store
        .dispatch("admins/update", { form })
        .then(() => {
          this.$bus.$emit("showSnackbar", {
            text: "Data updated",
            color: "success",
          });
          this.HIDE_FORM();
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
          for (let key in error.response.data.errors) {
            this.$bus.$emit("showSnackbar", {
              text: error.response.data.errors[key][0],
              color: "error",
            });
          }
        })
        .finally(() => {
          this.formLoading = false;
          this.fetchRecords()
        });
    },
  },
};
</script>
